<template>
	<Modal :title="title" v-model="show" class-name="vertical-center-modal" @on-cancel="cancel"
		:loading="loading" :mask-closable="false" :footer-hide="true"	>
		<Form :model="formItem" :label-width="80" :rules="ruleFormItem" ref="form" >
			<FormItem label="商户APIV3密钥" prop="apiV3Key">
				<Input v-model="formItem.apiV3Key" placeholder="请输入商户APIV3密钥"></Input>
			</FormItem>
			<FormItem label="商户appid" prop="appid">
				<Input v-model="formItem.appid" placeholder="请输入商户appid"></Input>
			</FormItem>
			<FormItem label="商户证书序列号" prop="merchantSerialNumber">
				<Input v-model="formItem.merchantSerialNumber" placeholder="请输入商户证书序列号"></Input>
			</FormItem>
			<FormItem label="商户名称" prop="name">
				<Input v-model="formItem.name" placeholder="请输入商户名称"></Input>
			</FormItem>
			<FormItem label="支付平台" prop="payPlatform">
				<RadioGroup v-model="formItem.payPlatform">
					<Radio label="WX">微信</Radio>
					<Radio label="ALIPAY">支付宝</Radio>
				</RadioGroup>
			</FormItem>
			<FormItem label="商户API私钥路径" prop="privateKeyPath">
				<Upload ref="upload" :max-size="2048" :before-upload="handleBeforeUpload" multiple type="drag" action=""
					:show-upload-list="false">
					<Button icon="ios-cloud-upload-outline">上传商户API私钥</Button>
				</Upload>
				<div>{{formItem.privateKeyPath}}</div>
			</FormItem>
			<FormItem label="商户证书" prop="wechatPayCertificatePath">
				<Upload ref="upload1" :format="['jpg','jpeg','png']" :max-size="5048"
					:before-upload="handleBeforeUpload1" multiple type="drag" action="" :show-upload-list="false">
					<Button icon="ios-cloud-upload-outline">上传证书</Button>
				</Upload>
				<div>{{formItem.wechatPayCertificatePath}}</div>
			</FormItem>
			<FormItem style="text-align: right;">
				<Button type="primary" @click="cancel">取消</Button>
				<Button @click="save" style="margin-left: 8px">提交</Button>
			</FormItem>
			
		</Form>
	</Modal>
</template>
<script>
	import {
		weChatMerchantConfigUpload,
		weChatMerchantConfigAdd
	} from '@/api/merchant/merchant.js'
	export default {
		name: "AddRole",
		data() {
			return {
				title: "新增商户",

				formItem: {
					apiV3Key: '', //	商户APIV3密钥
					appid: '', //商户appid
					merchantSerialNumber: '', //商户证书序列号
					name: '', //商户名称
					payPlatform: '', //支付平台 [ WX, ALIPAY ]
					privateKeyPath: '', //商户API私钥路径
					wechatPayCertificatePath: '' //证书路径
				},
				ruleFormItem: {
					apiV3Key: [{
						required: true,
						message: '商户APIV3密钥不得为空',
						trigger: 'blur'
					}],
					appid: [{
						required: true,
						message: '商户appid不得为空',
						trigger: 'blur'
					}],
					merchantSerialNumber: [{
						required: true,
						message: '商户证书序列号不得为空',
						trigger: 'blur'
					}],
					name: [{
						required: true,
						message: '商户名称不得为空',
						trigger: 'blur'
					}],
					payPlatform: [{
						required: true,
						message: '请选择支付平台',
						trigger: 'blur'
					}],
					privateKeyPath: [{
						required: true,
						message: '请上传商户API私钥',
						trigger: 'blur'
					}],
					wechatPayCertificatePath: [{
						required: true,
						message: '请上传商户证书',
						trigger: 'blur'
					}],
				},
				show: false,
				loading: false
			}
		},
		props: {
			addmerchantShow: {
				type: Boolean,
				default: true,
			}
		},
		methods: {
			cancel() {
				this.$parent.addmerchantShow = false
			},
			save() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						this.loading = true
						weChatMerchantConfigAdd(this.formItem).then(() => {
							this.$Message.info({
								content: '保存成功'
							});
							this.$parent.loadData();
							this.loading = false;
							this.cancel()
						}).catch(() => {
							this.loading = false
						})
					} 
				})

			},
			handleBeforeUpload(file) {
				this.uploadFile(file, 1)
			},
			handleBeforeUpload1(file) {
				this.uploadFile(file, 2)
			},
			uploadFile(file, type) {
				if (this.formItem.appid == '' || !this.formItem.appid) {
					this.$Message.error('appid不得为空')
					return false
				}
				// 清除上一次选择的文件 避免后面不在触发此事件
				this.$refs["upload"].clearFiles();
				this.$refs["upload1"].clearFiles();
				this.rootFileName = file.name; // 上传文件名称
				const reader = new FileReader();
				//将文件读取为 DataURL 以data:开头的字符串
				reader.readAsDataURL(file);
				reader.onload = (e) => {
					// 读取到的图片base64 数据编码 将此编码字符串传给后台即可
					const code = e.target.result;
					let baseStr = code.substring(code.indexOf(",") + 1, code.length);
					weChatMerchantConfigUpload({
						appid: this.formItem.appid,
						fileContent: baseStr,
						filename: file.name
					}).then(res => {
						console.log(res)
						if (type == 1) {
							this.formItem.privateKeyPath = res.data.data

						} else {
							this.formItem.wechatPayCertificatePath = res.data.data

						}
					})
				};
			}
		},
		watch: {
			addmerchantShow() {
				this.show = this.addmerchantShow;
			},
			show() {
				this.formItem = {
					apiV3Key: '', //	商户APIV3密钥
					appid: '', //商户appid
					merchantSerialNumber: '', //商户证书序列号
					name: '', //商户名称
					payPlatform: '', //支付平台 [ WX, ALIPAY ]
					privateKeyPath: '', //商户API私钥路径
					wechatPayCertificatePath: '' //证书路径
				};
				this.$parent.addmerchantShow = this.show;
			}
		}
	}
</script>

<style scoped>
	.vertical-center-modal {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.ivu-modal {
		top: 0;
	}
</style>
<template>
	<div>
		<Form :model="search" :label-width="80">
			<Row>
				<Col span="4">
				<FormItem label="商户名称">
					<Input v-model="search.name" placeholder="请输入商户名称"></Input>
				</FormItem>
				</Col>
				<Col span="4">
				<FormItem label="商户Appid">
					<Input v-model="search.appid" placeholder="请输入商户Appid"></Input>
				</FormItem>
				</Col>
				<Col span="14">
				<Button type="primary" class="margin_left_10" @click.native="loadData">查询</Button>
				<Button type="primary" class="margin_left_10" @click.native="showAddmerchant">新增</Button>
				</Col>
			</Row>
		</Form>
		<Table :columns="columns" :data="data">

			<template slot-scope="{ row }" slot="action">
				<Button size="small" type="primary" @click.native="updatemerchant(row)"
					class="margin_right_10">修改</Button>
				<Button size="small" type="primary" @click.native="merchantWeChatConfig(row)"
						class="margin_right_10">小程序配置</Button>
				<Button size="small" type="error" @click.native="delmerchant(row)"
					class="margin_right_10">删除</Button>
			</template>
		</Table>
		<Page class="margin_10" v-if="page.pages > 1" :total="page.total" :current="page.pageNum"
			@on-change="changePage" show-elevator show-sizer />
		<Addmerchant :addmerchantShow="addmerchantShow"></Addmerchant>
		<Updatemerchant :updatemerchantShow="updatemerchantShow" :roleInfo="roleInfo"></Updatemerchant>
		
		<weChatConfig :weChatConfigShow="weChatConfigShow" :wxchatInfo="wxchatInfo"></weChatConfig>
	</div>
</template>
<script>
	import Addmerchant from './Addmerchant.vue';
	import Updatemerchant from './Updatemerchant.vue';
	import weChatConfig from './weChatConfig.vue';

	import {
		weChatMerchantConfigDel,
		weChatMerchantConfigPage,
		weChatMiniProgramConfigGet
	} from '@/api/merchant/merchant.js'
	export default {
		name: 'merchantInfo',
		components: {
			Addmerchant,
			Updatemerchant,
			weChatConfig
		},
		data() {
			return {
				addmerchantShow: false,
				updatemerchantShow: false,
				weChatConfigShow:false,
				roleInfo: {},
				wxchatInfo: {},
				updateStatusLoading: false,
				search: {
					name: "",
					appid: ""
				},
				page: {
					pageNum: 1,
					pageSize: 10,
					total: 0,
					pages: 1
				},
				columns: [{
						title: '商户appid',
						key: 'appid'
					},
					{
						title: '商户名称',
						key: 'name'
					},
					{
						title: '支付平台',
						key: 'payPlatform'
					},
					{
						title: '创建时间',
						key: 'updatedAt'
					},
					{
						title: '更新人',
						key: 'updater'
					},
					{
						title: '操作',
						slot: 'action'
					}
				],
				data: []
			}
		},
		created() {
			//this.loadData();
		},
		methods: {
			loadData: function() {
				let page = {
					pageNum: this.page.pageNum - 1,
					pageSize: this.page.pageSize
				}
				let _search = Object.assign({}, this.search, page);
				weChatMerchantConfigPage(_search).then(res => {
					console.log(32, res)
					this.data = res.data.data.data
					this.page = {
						total: res.data.data.total,
						pageSize: res.data.data.pageSize,
						pageNum: res.data.data.pageNum,
						pages: res.data.data.pages
					}
				}).catch(reason => {
					console.log(reason);
				})
			},
			changePage(pageNum) {
				this.page.pageNum = pageNum;
				this.loadData();
			},
			showAddmerchant() {
				this.addmerchantShow = true;
			},
			// 小程序配置
			merchantWeChatConfig(item){
				this.weChatConfigShow = true;
				this.wxchatInfo={weChatMerchantConfigUuid:item.uuid}
				this.getweChatMiniProgramConfigGet(item)
			},
			delmerchant(item) {
				this.$confirm('此操作将删除商户, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					weChatMerchantConfigDel({uuid:item.uuid}).then(() => {
						this.loadData();
					}).catch(reason => {
						console.log(reason);
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除操作'
					});
				});
			},
			updatemerchant(data) {
				this.updatemerchantShow = true;
				this.roleInfo = data;
			},
			updateStatus(data) {
				this.updateStatusLoading = true;
				let status = data.status == 1 ? 0 : 1;
				this.post("/permission/role/status/update", {
					uuid: data.uuid,
					status: status
				}).then(() => {
					this.loadData();
					this.$Message.info({
						content: status == 1 ? '启用成功' : "禁用成功"
					});
					this.updateStatusLoading = false;
				}).catch(reason => {
					console.log(reason);
					this.$Message.info({
						content: status == 1 ? '启用失败' : "禁用失败"
					});
					this.updateStatusLoading = false;
				})
			},
			getweChatMiniProgramConfigGet(item){
				weChatMiniProgramConfigGet({weChatMerchantConfigUuid:item.uuid}).then((res) => {
					console.log(res.data.data)
					this.wxchatInfo=res.data.data
				}).catch(() => {
					
				})
			},
		}
	}
</script>

<style>
</style>
<template>
	<Modal :title="title" v-model="show" class-name="vertical-center-modal" @on-cancel="cancel" @on-ok="save"
		:loading="loading" :mask-closable="false" :footer-hide="true"	>
		<Form :model="formItem" :label-width="80" ref="form">

			<FormItem label="小程序appid">
				<Input v-model="formItem.appid" placeholder="请输入小程序appid"></Input>
			</FormItem>
			<FormItem label="小程序secret">
				<Input v-model="formItem.secret" placeholder="请输入小程序secret"></Input>
			</FormItem>
			<FormItem label="小程序名">
				<Input v-model="formItem.name" placeholder="请输入小程序名"></Input>
			</FormItem>
			<FormItem style="text-align: right;">
				<Button @click="cancel">取消</Button>
				<Button type="error" @click="del"  style="margin:0 8px">删除</Button>
				<Button type="primary" @click="save">提交</Button>
			</FormItem>
		</Form>
	</Modal>
</template>
<script>
	import {
		weChatMiniProgramConfigSave,
		weChatMiniProgramConfigDel
	} from '@/api/merchant/merchant.js'
	export default {
		name: "weChatConfig",
		data() {
			return {
				title: "小程序配置",
				formItem: {
					appid: '', //小程序APPID
					secret: '', //小程序secret
					name: '', //小程序名
					
				},
				ruleFormItem: {
					appid: [{
						required: true,
						message: '小程序appid不得为空',
						trigger: 'blur'
					}],
					secret: [{
						required: true,
						message: '小程序secret不得为空',
						trigger: 'blur'
					}],
					name: [{
						required: true,
						message: '小程序名不得为空',
						trigger: 'blur'
					}],
					
				},
				show: false,
				loading: false
			}
		},
		props: {
			weChatConfigShow: {
				type: Boolean,
				default: true,
			},
			wxchatInfo: {}
		},
		mounted() {
			
		},
		methods: {
			cancel() {
				this.$parent.weChatConfigShow = false
				this.$parent.wxchatInfo = {};
			},
			
			save() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						this.loading = true
						weChatMiniProgramConfigSave(this.formItem).then(() => {
							this.$Message.info({
								content: '保存成功'
							});
							this.$parent.loadData();
							this.loading = false;
							this.cancel()
						}).catch(() => {
							this.loading = false
						})
					} 
				})
			
			},
			del(){
				this.$confirm('此操作将删除小程序配置信息, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					weChatMiniProgramConfigDel({appid:this.formItem.appid}).then(() => {
						this.$parent.loadData();
						this.loading = false;
						this.cancel()
					}).catch(reason => {
						this.loading = false
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除操作'
					});
				});
				
			}
		},
		watch: {
			weChatConfigShow() {
				this.show = this.weChatConfigShow;
			},
			show() {
				if (!this.show) {
					this.formItem = {
						appid: '', //小程序APPID
						secret: '', //小程序secret
						name: '', //小程序名
					};
					this.$parent.weChatConfigShow = this.show;
					
				}
			},
			wxchatInfo() {
				Object.assign(this.formItem, this.wxchatInfo)
				console.log(11,this.wxchatInfo)
			},
			
		}
	}
</script>

<style scoped>
	.vertical-center-modal {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.ivu-modal {
		top: 0;
	}
</style>